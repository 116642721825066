import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { TeamSearchResultAvatar } from "./TeamSearchResultAvatar";
export var TeamSearchResultView = function (_a) {
    var teamSearchResult = _a.teamSearchResult, _b = _a.variant, variant = _b === void 0 ? "h6" : _b;
    return (_jsxs(Stack, { direction: "row", spacing: 2, alignItems: "center", children: [_jsx(TeamSearchResultAvatar, { teamSearchResult: teamSearchResult }), _jsx(Typography, { variant: variant, children: _jsx(TeamSearchResultDisplayNameLink, { teamSearchResult: teamSearchResult }) })] }));
};
var TeamSearchResultDisplayNameLink = function (_a) {
    var teamSearchResult = _a.teamSearchResult;
    var displayName = teamSearchResult.name;
    return (_jsx(Link, { style: { textDecoration: "none" }, to: "/teams/".concat(teamSearchResult.id, "/"), children: displayName }, teamSearchResult.id));
};
