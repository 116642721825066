var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Avatar, Box, CircularProgress, IconButton, MenuItem, TextField, } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSearchPlayers, useSearchTeams } from "../ApiProvider";
var DEBOUNCE_TIME = 500;
var SearchType;
(function (SearchType) {
    SearchType[SearchType["Players"] = 0] = "Players";
    SearchType[SearchType["Teams"] = 1] = "Teams";
})(SearchType || (SearchType = {}));
export var SearchBar = function () {
    var _a = __read(useState(false), 2), searchOpen = _a[0], setSearchOpen = _a[1];
    var _b = __read(useState(SearchType.Teams), 2), searchType = _b[0], setSearchType = _b[1];
    return (_jsx(_Fragment, { children: searchOpen ? (_jsxs(Box, { sx: { display: "flex", alignItems: "center", ml: 1, height: 40 }, children: [_jsxs(TextField, { select: true, value: searchType, onChange: function (e) { return setSearchType(Number(e.target.value)); }, size: "small", sx: {
                        width: 120,
                        bgcolor: "white",
                        "&:hover": { bgcolor: "#f0f0f0" },
                        borderRadius: 1,
                        fontSize: "0.875rem",
                        height: 36,
                    }, children: [_jsx(MenuItem, { value: SearchType.Teams, children: _jsx("div", { "data-testid": "teams-option", children: "Teams" }) }), _jsx(MenuItem, { value: SearchType.Players, children: _jsx("div", { "data-testid": "players-option", children: "Players" }) })] }), searchType === SearchType.Players ? (_jsx(PlayerSearchAutocomplete, { setSearchOpen: setSearchOpen })) : (_jsx(TeamSearchAutocomplete, { setSearchOpen: setSearchOpen })), _jsx(IconButton, { onClick: function () { return setSearchOpen(false); }, children: _jsx(CloseIcon, { sx: { color: "white" } }) })] })) : (_jsx(IconButton, { "data-testid": "search-button", color: "inherit", onClick: function () { return setSearchOpen(true); }, children: _jsx(SearchIcon, {}) })) }));
};
var PlayerSearchAutocomplete = function (_a) {
    var setSearchOpen = _a.setSearchOpen;
    var _b = __read(useState(""), 2), searchQuery = _b[0], setSearchQuery = _b[1];
    var debouncedSearchQuery = useDebounce(searchQuery, DEBOUNCE_TIME);
    var _c = useSearchPlayers({
        searchTerm: debouncedSearchQuery,
        enabled: debouncedSearchQuery.length > 2,
    }), data = _c.data, isLoading = _c.isLoading;
    var onSearchChange = function (_event, newValue) {
        setSearchQuery(newValue || "");
    };
    var isLoadSearching = isLoading && searchQuery.length > 2;
    var players = data === null || data === void 0 ? void 0 : data.results;
    var navigate = useNavigate();
    var onSearchSubmit = function () {
        if (searchQuery.trim()) {
            navigate("/search/players/?q=".concat(encodeURIComponent(searchQuery)));
        }
    };
    return (_jsx(Autocomplete, { options: players || [], loading: isLoadSearching, open: !!players && players.length > 0, filterOptions: function (x) { return x; }, popupIcon: null, sx: { ml: 1 }, getOptionLabel: function (option) { return "".concat(option.firstName, " ").concat(option.lastName); }, onInputChange: onSearchChange, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { placeholder: "Search Players", size: "small", variant: "outlined", "data-testid": "search-input", sx: {
                bgcolor: "white",
                borderRadius: 2,
                minWidth: 300,
            }, onKeyDown: function (e) {
                if (e.key === "Enter") {
                    onSearchSubmit();
                }
            }, InputProps: __assign(__assign({}, params.InputProps), { endAdornment: (_jsxs(_Fragment, { children: [isLoadSearching ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, _jsx(IconButton, { sx: { p: 0.5 }, onClick: onSearchSubmit, children: _jsx(SearchIcon, { fontSize: "small" }) }), params.InputProps.endAdornment] })) }) }))); }, renderOption: function (props, option) { return (_jsx(Link, { to: "/players/".concat(option.id), style: { textDecoration: "none", color: "inherit", width: "100%" }, onClick: function () { return setSearchOpen(false); }, children: _jsxs(Box, __assign({ component: "li" }, props, { sx: {
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    px: 2,
                    py: 1,
                    "&:hover": { bgcolor: "#f0f0f0" },
                }, children: [_jsx(Avatar, { src: option.picture }), _jsxs("span", { children: [option.firstName, " ", option.lastName] })] })) })); } }));
};
var TeamSearchAutocomplete = function (_a) {
    var setSearchOpen = _a.setSearchOpen;
    var _b = __read(useState(""), 2), searchQuery = _b[0], setSearchQuery = _b[1];
    var navigate = useNavigate();
    var onSearchChange = function (_event, newValue) {
        setSearchQuery(newValue || "");
    };
    var debouncedSearchQuery = useDebounce(searchQuery, DEBOUNCE_TIME);
    var _c = useSearchTeams({
        searchTerm: debouncedSearchQuery,
        enabled: debouncedSearchQuery.length > 2,
    }), data = _c.data, isLoading = _c.isLoading;
    var isLoadSearching = isLoading && searchQuery.length > 2;
    var teams = data === null || data === void 0 ? void 0 : data.results;
    var onSearchSubmit = function () {
        if (searchQuery.trim()) {
            navigate("/search/teams/?q=".concat(encodeURIComponent(searchQuery)));
        }
    };
    return (_jsx(Autocomplete, { options: teams || [], loading: isLoadSearching, open: !!teams && teams.length > 0, getOptionLabel: function (option) { return option.name; }, filterOptions: function (x) { return x; }, sx: { ml: 1 }, onInputChange: onSearchChange, popupIcon: null, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { placeholder: "Search Teams", size: "small", variant: "outlined", "data-testid": "search-input", sx: {
                bgcolor: "white",
                borderRadius: 2,
                minWidth: 300,
            }, onKeyDown: function (e) {
                if (e.key === "Enter") {
                    onSearchSubmit();
                }
            }, InputProps: __assign(__assign({}, params.InputProps), { endAdornment: (_jsxs(_Fragment, { children: [isLoadSearching ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, _jsx(IconButton, { sx: { p: 0.5 }, onClick: onSearchSubmit, children: _jsx(SearchIcon, { fontSize: "small" }) }), params.InputProps.endAdornment] })) }) }))); }, renderOption: function (props, option) { return (_jsx(Link, { to: "/teams/".concat(option.id), style: { textDecoration: "none", color: "inherit", width: "100%" }, onClick: function () { return setSearchOpen(false); }, children: _jsxs(Box, __assign({ component: "li" }, props, { sx: {
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    px: 2,
                    py: 1,
                    "&:hover": { bgcolor: "#f0f0f0" },
                }, children: [_jsx(Avatar, { src: option.logo || undefined }), _jsx("span", { children: option.name })] })) })); } }));
};
var useDebounce = function (value, delay) {
    if (delay === void 0) { delay = 300; }
    var _a = __read(useState(value), 2), debouncedValue = _a[0], setDebouncedValue = _a[1];
    useEffect(function () {
        var handler = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        return function () {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
};
