import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardContent, Divider, Grid, Typography, } from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useRetrievePlayerByUserId, useRetrieveTeamsByPlayerId, } from "../ApiProvider";
import { UserContext } from "../auth/UserProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import { TeamCard } from "./TeamCard";
var Teams = function () {
    var _a = useContext(UserContext), contextUser = _a.user, isContextUserLoading = _a.loading;
    var _b = useRetrievePlayerByUserId({ userId: contextUser === null || contextUser === void 0 ? void 0 : contextUser.pk }), isPlayerLoading = _b.isLoading, playerError = _b.error, player = _b.data;
    var _c = useRetrieveTeamsByPlayerId({
        playerId: player === null || player === void 0 ? void 0 : player.id,
    }), isLoading = _c.isLoading, error = _c.error, teams = _c.data;
    if (isLoading || isPlayerLoading || isContextUserLoading) {
        return _jsx(Loading, {});
    }
    if (error || teams === undefined || playerError) {
        return (_jsx(ErrorView, { error: error || new Error("Failed to retrieve teams data.") }));
    }
    return (_jsxs(React.Fragment, { children: [_jsxs(Grid, { container: true, spacing: 2, alignItems: "center", children: [_jsx(Grid, { item: true, xs: true, children: _jsx(Typography, { variant: "h4", gutterBottom: true, children: "Teams" }) }), _jsx(Grid, { item: true, children: _jsx(Link, { to: "/teams/create/", style: { textDecoration: "none" }, children: _jsx(Button, { variant: "contained", color: "primary", size: "large", children: "Create Team" }) }) })] }), _jsx(Divider, { sx: { my: 2 } }), teams.length > 0 ? (_jsx(Grid, { container: true, spacing: 2, children: teams.map(function (team) { return (_jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: _jsx(TeamCard, { team: team }) }, team.id)); }) })) : (_jsx(Card, { sx: { minWidth: 275, mt: 2 }, children: _jsxs(CardContent, { children: [_jsx(Typography, { variant: "h5", component: "div", children: "No teams found!" }), _jsx(Typography, { variant: "body2", sx: { mt: 1 }, children: "You're currently not part of any teams. Why not create one now?" })] }) }))] }));
};
export default Teams;
