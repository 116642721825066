import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
export var UserMenu = function (_a) {
    var anchorElUser = _a.anchorElUser, onUserMenuClose = _a.onUserMenuClose, onLogoutClick = _a.onLogoutClick;
    return (_jsxs(Menu, { sx: { mt: "45px" }, id: "menu-appbar", anchorEl: anchorElUser, anchorOrigin: {
            vertical: "top",
            horizontal: "right",
        }, keepMounted: true, transformOrigin: {
            vertical: "top",
            horizontal: "right",
        }, open: Boolean(anchorElUser), onClose: onUserMenuClose, children: [_jsx(MenuItem, { onClick: onUserMenuClose, children: _jsx(Button, { component: Link, to: "/account", children: "My Account" }) }, "account-key"), _jsx(MenuItem, { onClick: onUserMenuClose, children: _jsx(Button, { component: Link, to: "/privacy-settings", children: "Privacy Settings" }) }, "privacy-settings-key"), _jsx(MenuItem, { onClick: onUserMenuClose, children: _jsx(Button, { onClick: onLogoutClick, children: "Logout" }) }, "Logout")] }));
};
