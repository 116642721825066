import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { PlayerSearchResultAvatar } from "./PlayerSearchResultAvatar";
export var PlayerSearchResultView = function (_a) {
    var playerSearchResult = _a.playerSearchResult, _b = _a.variant, variant = _b === void 0 ? "h6" : _b;
    return (_jsxs(Stack, { direction: "row", spacing: 2, alignItems: "center", children: [_jsx(PlayerSearchResultAvatar, { playerSearchResult: playerSearchResult }), _jsx(Typography, { variant: variant, children: _jsx(PlayerSearchResultDisplayNameLink, { playerSearchResult: playerSearchResult }) })] }));
};
export var playerDisplayName = function (player) {
    if (!player) {
        return "Unknown Player";
    }
    var model = player.user || player.externalUser;
    if (model === undefined) {
        throw new Error("Cannot retrieve Player User!");
    }
    return "".concat(model.firstName, " ").concat(model.lastName);
};
var PlayerSearchResultDisplayNameLink = function (_a) {
    var playerSearchResult = _a.playerSearchResult;
    var displayName = "".concat(playerSearchResult === null || playerSearchResult === void 0 ? void 0 : playerSearchResult.firstName, " ").concat(playerSearchResult === null || playerSearchResult === void 0 ? void 0 : playerSearchResult.lastName);
    return (_jsx(Link, { style: { textDecoration: "none" }, to: "/players/".concat(playerSearchResult.id, "/"), children: displayName }, playerSearchResult.id));
};
