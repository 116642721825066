import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
export var TeamSearchResultAvatar = function (_a) {
    var _b;
    var teamSearchResult = _a.teamSearchResult, _c = _a.size, size = _c === void 0 ? 56 : _c;
    var displayName = teamSearchResult.name;
    var tooltip = (_jsx(Tooltip, { title: displayName, arrow: true, children: _jsx(Avatar, { src: teamSearchResult.logo || undefined, alt: displayName, sx: { width: size, height: size }, children: (_b = displayName.match(/\b(\w)/g)) === null || _b === void 0 ? void 0 : _b.join("") }) }));
    if (!teamSearchResult) {
        return tooltip;
    }
    return (_jsx(Link, { style: { textDecoration: "none" }, to: "/teams/".concat(teamSearchResult === null || teamSearchResult === void 0 ? void 0 : teamSearchResult.id, "/"), children: tooltip }, teamSearchResult === null || teamSearchResult === void 0 ? void 0 : teamSearchResult.id));
};
