var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Checkbox, FormControlLabel, FormLabel, MenuItem, TextField, } from "@mui/material";
import { CirclePicker } from "react-color";
import { Controller, useForm } from "react-hook-form";
import { FileUploadPreview } from "../utils/FileUploadPreview";
var SOCCER_JERSEY_COLORS = [
    "#D32F2F", // Red
    "#E64A19", // Orange Red
    "#F57C00", // Orange
    "#FBC02D", // Yellow
    "#F48FB1", // Pink
    "#F06292", // Hot Pink
    "#C62828", // Crimson
    "#8E24AA", // Firebrick
    "#795548", // Brown
    "#FFD54F", // Goldenrod
    "#AED581", // Lime Green
    "#388E3C", // Dark Green
    "#4CAF50", // Green
    "#26C6DA", // Aqua
    "#29B6F6", // Sky Blue
    "#42A5F5", // Steel Blue
    "#1976D2", // Blue
    "#5C6BC0", // Indigo
    "#000080", // Navy
    "#7E57C2", // Purple
    "#B3E5FC", // Powder Blue
    "#78909C", // Dark Gray
    "#757575", // Black (Gray)
    "#E0E0E0", // White (Light Gray)
    "#000000", // Black
    "#FFFFFF", // White
];
export var TeamForm = function (_a) {
    var team = _a.team, onSubmit = _a.onSubmit, addAsPlayer = _a.addAsPlayer, setAddAsPlayer = _a.setAddAsPlayer;
    var _b = useForm(), control = _b.control, handleSubmit = _b.handleSubmit, setValue = _b.setValue, errors = _b.formState.errors;
    var handleCheckboxChange = function (event) {
        setAddAsPlayer(event.target.checked);
    };
    var onChange = function (base64String) {
        setValue("logo", base64String);
    };
    return (_jsxs(Box, { component: "form", onSubmit: handleSubmit(onSubmit), noValidate: true, role: "form", sx: { mt: 1 }, children: [_jsx(Controller, { name: "name", control: control, defaultValue: (team === null || team === void 0 ? void 0 : team.name) || "", rules: { required: "Team Name is required" }, render: function (_a) {
                    var field = _a.field;
                    return (_jsx(TextField, __assign({}, field, { margin: "normal", required: true, fullWidth: true, id: "name", label: "Team Name", autoComplete: "teamName", autoFocus: true, error: !!errors.name, helperText: errors.name ? errors.name.message : "" })));
                } }), _jsx(Controller, { name: "nickname", control: control, defaultValue: (team === null || team === void 0 ? void 0 : team.nickname) || "", render: function (_a) {
                    var field = _a.field;
                    return (_jsx(TextField, __assign({}, field, { margin: "normal", fullWidth: true, id: "nickname", label: "Team Nickname", autoComplete: "teamNickname" })));
                } }), _jsx(FormControlLabel, { sx: { mt: 1 }, control: _jsx(Checkbox, { checked: addAsPlayer, onChange: handleCheckboxChange, color: "primary" }), label: "Add me as a player in this team" }), _jsx(Controller, { name: "establishedYear", control: control, defaultValue: (team === null || team === void 0 ? void 0 : team.establishedYear) || new Date().getFullYear(), rules: { required: "Established Year is required" }, render: function (_a) {
                    var field = _a.field;
                    return (_jsx(TextField, __assign({}, field, { select: true, fullWidth: true, margin: "normal", required: true, id: "establishedYear", label: "Established Year", error: !!errors.establishedYear, helperText: errors.establishedYear ? errors.establishedYear.message : "", children: Array.from({ length: new Date().getFullYear() - 1860 }, function (_, i) {
                            var year = new Date().getFullYear() - i;
                            return (_jsx(MenuItem, { value: year, children: year }, year));
                        }) })));
                } }), _jsxs(Box, { mt: 2, position: "relative", children: [_jsx(FormLabel, { style: {
                            position: "absolute",
                            top: "-10px",
                            left: "12px",
                            backgroundColor: "white",
                            padding: "0 4px",
                            zIndex: 1,
                            fontSize: "0.75rem",
                        }, children: "Primary Color" }), _jsx(Box, { p: { xs: 1, sm: 2 }, border: 1, borderColor: "grey.500", borderRadius: 1, style: {
                            backgroundImage: "linear-gradient(45deg, #f3f3f3 25%, transparent 25%, transparent 75%, #f3f3f3 75%), linear-gradient(45deg, #f3f3f3 25%, transparent 25%, transparent 75%, #f3f3f3 75%)",
                            backgroundSize: "20px 20px",
                            backgroundPosition: "0 0, 10px 10px",
                        }, children: _jsx(Controller, { name: "primaryColour", control: control, defaultValue: (team === null || team === void 0 ? void 0 : team.primaryColour) || "#000000", render: function (_a) {
                                var field = _a.field;
                                return (_jsx(CirclePicker, { width: "100%", colors: SOCCER_JERSEY_COLORS, color: field.value, onChange: function (color) { return field.onChange(color.hex); } }));
                            } }) })] }), _jsxs(Box, { mt: 2, position: "relative", children: [_jsx(FormLabel, { style: {
                            position: "absolute",
                            top: "-10px",
                            left: "12px",
                            backgroundColor: "white",
                            padding: "0 4px",
                            zIndex: 1,
                            fontSize: "0.75rem",
                        }, children: "Secondary Color" }), _jsx(Box, { p: { xs: 1, sm: 2 }, border: 1, borderColor: "grey.500", borderRadius: 1, style: {
                            backgroundImage: "linear-gradient(45deg, #f3f3f3 25%, transparent 25%, transparent 75%, #f3f3f3 75%), linear-gradient(45deg, #f3f3f3 25%, transparent 25%, transparent 75%, #f3f3f3 75%)",
                            backgroundSize: "20px 20px",
                            backgroundPosition: "0 0, 10px 10px",
                        }, children: _jsx(Controller, { name: "secondaryColour", control: control, defaultValue: (team === null || team === void 0 ? void 0 : team.secondaryColour) || "#ffffff", render: function (_a) {
                                var field = _a.field;
                                return (_jsx(CirclePicker, { width: "100%", colors: SOCCER_JERSEY_COLORS, color: field.value, onChange: function (color) { return field.onChange(color.hex); } }));
                            } }) })] }), _jsx(FileUploadPreview, { label: "Logo", onChange: onChange }), _jsx(Button, { type: "submit", fullWidth: true, variant: "contained", sx: { mt: 3, mb: 2 }, children: team === undefined ? "Create" : "Update" })] }));
};
