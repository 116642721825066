var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateIntraSquadMatch, useRetrieveTeam, useRetrieveUserTeamPermissions, } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Forbidden } from "../nav/Forbidden";
import { Loading } from "../nav/Loading";
import { useNumericParams } from "../utils/useNumericParams";
import { IntraSquadMatchForm } from "./IntraSquadMatchForm";
export var CreateIntraSquadMatch = function () {
    var teamId = useNumericParams().id;
    var _a = useRetrieveUserTeamPermissions({ teamId: teamId }), userTeamPermissions = _a.data, userTeamPermissionsIsLoading = _a.isLoading, userTeamPermissionsError = _a.error;
    var _b = (userTeamPermissions || {}).canModify, canModify = _b === void 0 ? false : _b;
    var _c = useRetrieveTeam({ id: teamId, enabled: canModify }), useRetrieveTeamIsLoading = _c.isLoading, useRetrieveTeamError = _c.error, team = _c.data;
    var _d = __read(useState(false), 2), loading = _d[0], setLoading = _d[1];
    var _e = __read(useState(undefined), 2), mutationError = _e[0], setError = _e[1];
    var navigate = useNavigate();
    var mutation = useCreateIntraSquadMatch({
        team: team,
        onMutate: function () {
            setLoading(true);
        },
        onSuccess: function (data) {
            var intraSquadMatch = data.data;
            return navigate("/intra-squad-matches/".concat(intraSquadMatch.id, "/"));
        },
        onError: function (error) {
            setLoading(false);
            setError(error);
        },
    });
    var onSubmit = function (intraSquadMatch) {
        mutation.mutate(intraSquadMatch);
    };
    if (userTeamPermissionsIsLoading) {
        return _jsx(Loading, {});
    }
    if (userTeamPermissionsError) {
        return _jsx(ErrorView, { error: userTeamPermissionsError });
    }
    if (!canModify) {
        return _jsx(Forbidden, {});
    }
    if (loading || useRetrieveTeamIsLoading) {
        return _jsx(Loading, {});
    }
    if (useRetrieveTeamError || !team) {
        return _jsx(ErrorView, { error: useRetrieveTeamError });
    }
    return (_jsxs(React.Fragment, { children: [mutationError && (_jsxs(Alert, { variant: "outlined", severity: "error", children: ["Failed to create team \u2014 ", mutationError.message, "!"] })), _jsx(Typography, { variant: "h6", children: _jsxs("strong", { children: ["Create Intra-Squad Match for ", team.name] }) }), _jsx(IntraSquadMatchForm, { team: team, onSubmit: onSubmit })] }));
};
