import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from "react";
import { useRetrieveTransferRequest } from "../api/TransferRequestApiProvider";
import { useRetrieveTeam } from "../ApiProvider";
import { UserContext } from "../auth/UserProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import { useNumericParams } from "../utils/useNumericParams";
import { ManagerTransferRequestView } from "./ManagerTransferRequestView";
import { PlayerTransferRequestView } from "./PlayerTransferRequestView";
export var TransferRequest = function () {
    var id = useNumericParams().id;
    var _a = useRetrieveTransferRequest({ id: id }), transferRequest = _a.data, error = _a.error, isLoading = _a.isLoading;
    var teamId = transferRequest === null || transferRequest === void 0 ? void 0 : transferRequest.team;
    var _b = useRetrieveTeam({ id: teamId }), team = _b.data, teamError = _b.error, teamIsLoading = _b.isLoading;
    var _c = useContext(UserContext), contextUser = _c.user, isContextUserLoading = _c.loading;
    if (isLoading || (!!teamId && teamIsLoading) || isContextUserLoading) {
        return _jsx(Loading, {});
    }
    if (error || !transferRequest || teamError || !team || !contextUser) {
        return _jsx(ErrorView, { error: error || teamError });
    }
    if (contextUser.pk === team.createdBy) {
        return (_jsx(ManagerTransferRequestView, { transferRequest: transferRequest, team: team }));
    }
    return (_jsx(PlayerTransferRequestView, { transferRequest: transferRequest, team: team }));
};
