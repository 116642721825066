import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Divider, Grid, IconButton, Stack, Typography, } from "@mui/material";
import { Link } from "react-router-dom";
import { calculateAge } from "./PlayerUtils";
var getColorForValue = function (value) {
    var green = Math.floor((value / 100) * 255); // From 0 to 255 for green
    var red = 255 - green; // From 255 to 0 for red
    return "rgb(".concat(red, ", ").concat(green, ", 0)"); // RGB color
};
export var PlayerStatsCard = function (_a) {
    var player = _a.player, canModify = _a.canModify;
    var height = player.heightFeet
        ? "".concat(player.heightFeet, "'").concat(player.heightInch || 0, "\"")
        : "-";
    var attributes = [
        { label: "Heading", value: player.heading },
        { label: "Passing", value: player.passing },
        { label: "Positioning", value: player.positioning },
        { label: "Defending", value: player.defending },
        { label: "Finishing", value: player.finishing },
        { label: "Dribbling", value: player.dribbling },
        { label: "Shooting", value: player.shooting },
        { label: "Tackling", value: player.tackling },
        { label: "Crossing", value: player.crossing },
        { label: "Leadership", value: player.leadership },
        { label: "Communication", value: player.communication },
        { label: "Speed", value: player.speed },
        { label: "Fitness", value: player.fitness },
        { label: "Goalkeeping", value: player.keeping },
    ];
    return (_jsxs("div", { children: [_jsxs(Box, { sx: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }, children: [_jsx(Typography, { variant: "h5", gutterBottom: true, children: "Player Bio" }), canModify && (_jsx(IconButton, { "aria-label": "edit", children: _jsx(Link, { to: "/players/".concat(player.id, "/edit/"), children: _jsx(EditIcon, {}) }) }))] }), _jsx(Divider, { sx: { marginBottom: 2 } }), _jsxs(Grid, { container: true, spacing: 3, children: [_jsxs(Grid, { item: true, xs: 12, sm: 6, children: [_jsx(Typography, { variant: "subtitle2", color: "textSecondary", children: "Age" }), _jsx(Typography, { children: (player.dob && calculateAge(player.dob)) || "-" })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, children: [_jsx(Typography, { variant: "subtitle2", color: "textSecondary", children: "Weight" }), _jsx(Typography, { children: player.weight ? "".concat(player.weight, " kg") : "-" })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, children: [_jsx(Typography, { variant: "subtitle2", color: "textSecondary", children: "Height" }), _jsx(Typography, { children: height })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, children: [_jsx(Typography, { variant: "subtitle2", color: "textSecondary", children: "Preferred Foot" }), _jsx(Typography, { children: player.foot || "-" })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, children: [_jsx(Typography, { variant: "subtitle2", color: "textSecondary", children: "Primary Position" }), _jsx(Typography, { children: player.positionOne || "-" })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, children: [_jsx(Typography, { variant: "subtitle2", color: "textSecondary", children: "Secondary Position" }), _jsx(Typography, { children: player.positionTwo || "-" })] })] }), _jsx(Divider, { sx: { marginBottom: 2, marginTop: 2 } }), _jsx(Stack, { children: attributes.map(function (attribute) { return (_jsx(Stack, { direction: "row", children: _jsxs(Box, { sx: {
                            display: "flex",
                            alignItems: "center",
                        }, children: [_jsx(Box, { sx: {
                                    backgroundColor: getColorForValue(attribute.value),
                                    color: "white",
                                    width: 50, // Fixed width for all boxes
                                    height: 50, // Fixed height for all boxes
                                    borderRadius: 1,
                                    margin: "5px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }, children: _jsx(Typography, { variant: "body1", children: attribute.value }) }), _jsx(Typography, { variant: "subtitle2", children: attribute.label })] }) })); }) })] }));
};
