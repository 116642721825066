var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import NotificationIcon from "@mui/icons-material/Notifications";
import { Badge, Box, Button, IconButton, List, ListItem, ListItemText, Popover, Tab, Tabs, } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useListNotifications, useMarkNotificationAsRead, } from "../api/NotificationApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import ErrorSnackbar from "../utils/ErrorSnackbar";
export var buildNotificationUrl = function (notification) {
    var verb = notification.verb, actionObject = notification.actionObject;
    switch (verb) {
        case "transfer_request":
            return "/transfers/".concat(actionObject.id);
        case "transfer_request_accepted":
            return "/teams/".concat(actionObject.id);
        default:
            throw new Error("Unknown notification verb: ".concat(verb));
    }
};
export var NotificationsBadge = function () {
    var _a;
    var _b = useListNotifications({}), notifications = _b.data, notificationsIsLoading = _b.isLoading, notificationsError = _b.error;
    var markNotificationAsReadMutation = useMarkNotificationAsRead();
    var _c = __read(useState(null), 2), anchorEl = _c[0], setAnchorEl = _c[1];
    var _d = __read(useState("all"), 2), tabValue = _d[0], setTabValue = _d[1];
    var navigate = useNavigate();
    var handleBadgeClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleNotificationClick = function (notification) {
        if (notification.unread) {
            markNotificationAsReadMutation.mutate(notification.id);
        }
        navigate(buildNotificationUrl(notification));
        handleClose();
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var open = Boolean(anchorEl);
    var handleTabChange = function (_event, newValue) {
        setTabValue(newValue);
    };
    var unreadCount = ((_a = notifications === null || notifications === void 0 ? void 0 : notifications.results) === null || _a === void 0 ? void 0 : _a.filter(function (n) { return n.unread; }).length) || 0;
    return (_jsxs(React.Fragment, { children: [_jsx(ErrorSnackbar, { open: !!notificationsError, message: "Failed to load user notifications!" }), _jsx(IconButton, { sx: { color: "white" }, onClick: handleBadgeClick, children: _jsx(Badge, { color: "secondary", badgeContent: notificationsIsLoading ? "..." : unreadCount, children: _jsx(NotificationIcon, {}) }) }), _jsx(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                }, transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }, children: _jsx(React.Fragment, { children: notificationsIsLoading ? (_jsx(Loading, {})) : notificationsError || !notifications ? (_jsx(ErrorView, {})) : (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { p: 1, borderBottom: 1, borderColor: "divider" }, children: _jsxs(Tabs, { value: tabValue, onChange: handleTabChange, centered: true, children: [_jsx(Tab, { label: "All", value: "all" }), _jsx(Tab, { label: "Unread", value: "unread" })] }) }), _jsx(List, { children: notifications.count === 0 ? (_jsx(ListItem, { children: _jsx(ListItemText, { primary: "No new notifications" }) })) : (notifications.results.map(function (notification) { return (_jsxs(ListItem, { onClick: function () { return handleNotificationClick(notification); }, sx: {
                                        bgcolor: notification.unread ? "#f0f0f0" : "white",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        cursor: "pointer",
                                    }, children: [_jsx(ListItemText, { primary: notification.description }), notification.unread && (_jsx(Box, { "data-testid": "unread-dot", sx: {
                                                width: 10,
                                                height: 10,
                                                bgcolor: "blue",
                                                borderRadius: "50%",
                                                ml: 2,
                                            } }))] }, notification.id)); })) }), _jsx(Box, { sx: {
                                    textAlign: "center",
                                    p: 1,
                                    borderTop: 1,
                                    borderColor: "divider",
                                }, children: _jsx(Button, { size: "small", onClick: function () {
                                        navigate("/notifications");
                                        handleClose();
                                    }, children: "See All" }) })] })) }) })] }));
};
