import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useCancelTransferRequest } from "../api/TransferRequestApiProvider";
import ErrorSnackbar from "../utils/ErrorSnackbar";
export var PlayerTransferRequestView = function (_a) {
    var transferRequest = _a.transferRequest, team = _a.team;
    var navigate = useNavigate();
    var cancelTransferRequestMutation = useCancelTransferRequest({
        transferRequest: transferRequest,
        onMutate: function () { },
        onSuccess: function () {
            navigate("/teams/".concat(team.id, "/"));
        },
        onError: console.error,
    });
    var status = transferRequest.status;
    return (_jsxs(Box, { sx: { display: "flex", justifyContent: "center", minHeight: "100vh" }, children: [_jsx(ErrorSnackbar, { open: cancelTransferRequestMutation.isError, message: "Failed to cancel transfer request! Please try again." }), _jsxs(Box, { sx: { p: 3, maxWidth: 400, width: "100%" }, children: [_jsx(Typography, { variant: "h5", gutterBottom: true, children: "Transfer Request" }), _jsxs(Typography, { children: ["Your transfer request to", " ", _jsx(Link, { style: { textDecoration: "none" }, to: "/teams/".concat(team.id, "/"), children: team.name }), " ", status === "PENDING" ? "is" : "was", " ", _jsx("strong", { children: status }), "."] }), status === "PENDING" && (_jsx(Box, { sx: { display: "flex", justifyContent: "center", gap: 2, mt: 3 }, children: _jsx(Button, { variant: "outlined", color: "error", onClick: function () {
                                cancelTransferRequestMutation.mutate();
                            }, children: "Cancel Transfer Request" }) }))] })] }));
};
