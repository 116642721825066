var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Container, Pagination, Paper, TextField, Typography, } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchPlayers } from "../ApiProvider";
import { Loading } from "../nav/Loading";
import { ErrorView } from "../nav/Error";
import ErrorSnackbar from "../utils/ErrorSnackbar";
import { PlayerSearchResultView } from "./PlayerSearchResultView";
export var SearchPlayerResults = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var queryParams = new URLSearchParams(location.search);
    var currentSearchTerm = queryParams.get("q") || "";
    var currentPage = Number.parseInt(queryParams.get("page") || "1");
    var _a = useSearchPlayers({
        searchTerm: currentSearchTerm,
    }), data = _a.data, isLoading = _a.isLoading, isError = _a.isError;
    var handleChange = function (_event, value) {
        navigate("/search/players?q=".concat(encodeURIComponent(currentSearchTerm), "&page=").concat(value));
    };
    var _b = __read(useState(currentSearchTerm), 2), searchTerm = _b[0], setSearchTerm = _b[1];
    var handleSearch = function () {
        navigate("/search/players/?q=".concat(encodeURIComponent(searchTerm)));
    };
    if (isLoading) {
        return _jsx(Loading, {});
    }
    if (!data) {
        return _jsx(ErrorView, {});
    }
    var pageCount = data.pageCount, results = data.results, count = data.count;
    return (_jsxs(Container, { maxWidth: "md", style: { marginTop: "20px" }, children: [_jsx(ErrorSnackbar, { open: !!isError, message: "Failed to search for players!" }), _jsx(Typography, { variant: "h4", gutterBottom: true, children: "Player Search" }), _jsxs("div", { style: { display: "flex", gap: "10px", marginBottom: "20px" }, children: [_jsx(TextField, { variant: "outlined", label: "Search players", value: searchTerm, onChange: function (e) { return setSearchTerm(e.target.value); }, fullWidth: true, onKeyDown: function (e) {
                            if (e.key === "Enter") {
                                handleSearch();
                            }
                        } }), _jsx(Button, { variant: "contained", color: "primary", onClick: handleSearch, children: "Search" })] }), count === 0 && _jsx(Typography, { children: "No players found!" }), count > 0 ? (_jsxs(Paper, { children: [results.map(function (playerSearchResult, index) { return (_jsx(Box, { display: "flex", alignItems: "center", padding: 2, bgcolor: index % 2 === 0 ? "#f9f9f9" : "#ffffff", borderBottom: "1px solid #ddd", children: _jsx(PlayerSearchResultView, { playerSearchResult: playerSearchResult }) }, playerSearchResult.id)); }), _jsx(Box, { display: "flex", justifyContent: "center", mt: 2, children: _jsx(Pagination, { showFirstButton: true, showLastButton: true, count: pageCount, page: currentPage, onChange: handleChange }) })] })) : null] }));
};
