import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Card, CardContent, Divider, Stack, Typography, } from "@mui/material";
import { Link } from "react-router-dom";
import { TeamAvatar } from "./TeamAvatar";
export var TeamsCard = function (_a) {
    var teams = _a.teams;
    var maxTeamsToShow = 5;
    var showViewAllLink = teams.length > maxTeamsToShow;
    return (_jsx(Card, { children: _jsxs(CardContent, { children: [_jsx(Typography, { variant: "h6", children: "Teams" }), _jsx(Divider, { sx: { marginBottom: 2 } }), _jsx(Stack, { spacing: 0, sx: { width: "100%" }, children: teams.length > 0 ? (teams.slice(0, maxTeamsToShow).map(function (team, index) { return (_jsx(Link, { to: "/teams/".concat(team.id, "/"), style: {
                            textDecoration: "none",
                            color: "inherit",
                            width: "100%",
                        }, children: _jsxs(Box, { sx: {
                                bgcolor: index % 2 === 0 ? "transparent" : "#f5f5f5",
                                marginX: -2, // Remove the L-shape gap
                                paddingY: 1.5,
                                paddingX: 2,
                                width: "calc(100% + 32px)", // Counter padding effect
                                display: "flex",
                                alignItems: "center",
                            }, children: [_jsx(TeamAvatar, { team: team }), _jsx(Typography, { variant: "body1", sx: { marginLeft: 2 }, children: team.name })] }) }, team.id)); })) : (_jsx(Typography, { component: Link, to: "/teams/create/", sx: {
                            textDecoration: "none",
                            color: "inherit",
                            textAlign: "center",
                            paddingY: 2,
                            width: "100%",
                            display: "block",
                        }, variant: "body1", children: "Add a New Team" })) }), showViewAllLink && (_jsx(Box, { sx: { marginTop: 2, textAlign: "center" }, children: _jsx(Link, { to: "/teams", style: { textDecoration: "none", color: "inherit" }, children: _jsx(Typography, { variant: "caption", color: "primary", children: "View All Teams" }) }) }))] }) }));
};
