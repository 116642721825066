var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Card, CardContent, CardHeader, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography, } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRetrieveUserPrivacySettings, useUpdateUserPrivacySettings, } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import ErrorSnackbar from "../utils/ErrorSnackbar";
export var PrivacySettings = function () {
    var _a = useRetrieveUserPrivacySettings(), isLoading = _a.isLoading, error = _a.error, data = _a.data;
    var navigate = useNavigate();
    var _b = useForm({
        defaultValues: {
            profileAccessControl: "public",
            teamAccessControl: "public",
        },
    }), control = _b.control, setValue = _b.setValue, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    useEffect(function () {
        if (data) {
            setValue("profileAccessControl", data.profileAccessControl);
            setValue("teamAccessControl", data.teamAccessControl);
        }
    }, [data, setValue]);
    var mutation = useUpdateUserPrivacySettings({
        onMutate: function () { },
        onSuccess: function () {
            navigate("/");
        },
        onError: console.error,
    });
    var onSubmit = function (data) {
        mutation.mutate(data);
    };
    if (isLoading || mutation.isLoading) {
        return _jsx(Loading, {});
    }
    if (error || !data) {
        return _jsx(ErrorView, {});
    }
    return (_jsxs(Box, { component: "form", onSubmit: handleSubmit(onSubmit), noValidate: true, role: "form", sx: { mt: 1 }, children: [_jsx(ErrorSnackbar, { open: !!mutation.isError, message: "Failed to update Privacy Settings!" }), _jsx(Typography, { variant: "h4", gutterBottom: true, children: "Privacy Settings" }), _jsx(Typography, { variant: "h6", gutterBottom: true, children: "Where You Appear" }), _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 6, children: _jsxs(Card, { sx: { mb: 2 }, children: [_jsx(CardHeader, { title: "Profile Page" }), _jsxs(CardContent, { children: [_jsx(Typography, { variant: "body2", color: "textSecondary", children: "Your profile page displays information about you, such as your name, activities, and stats. Parts of your profile page will always be publicly available." }), _jsxs(FormControl, { component: "fieldset", sx: { mt: 2 }, children: [_jsx(Typography, { sx: { mb: 2 }, variant: "subtitle2", children: "Who can see" }), _jsx(Controller, { name: "profileAccessControl", control: control, rules: { required: "Privacy setting is required" }, render: function (_a) {
                                                        var field = _a.field;
                                                        return (_jsxs(RadioGroup, __assign({}, field, { sx: { gap: 1 }, children: [_jsx(FormControlLabel, { value: "public", control: _jsx(Radio, {}), sx: { mb: 1 }, label: _jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body1", fontWeight: "bold", children: "Everyone" }), _jsx(Typography, { variant: "caption", children: "Anyone on Log.Soccer can search for and view your profile." })] }) }), _jsx(FormControlLabel, { value: "private", control: _jsx(Radio, {}), label: _jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body1", fontWeight: "bold", children: "Only You" }), _jsx(Typography, { variant: "caption", children: "Your profile is hidden from everyone except you." })] }) })] })));
                                                    } }), errors.profileAccessControl && (_jsx(Typography, { color: "error", variant: "body2", children: errors.profileAccessControl.message }))] })] })] }) }), _jsx(Grid, { item: true, xs: 6, children: _jsxs(Card, { sx: { mb: 2 }, children: [_jsx(CardHeader, { title: "Teams" }), _jsxs(CardContent, { children: [_jsx(Typography, { variant: "body2", color: "textSecondary", children: "Your teams display information about the groups you are part of, including team names and members. If your team access is set to public, anyone can see your teams. If set to private, only you and other users in your team can view them." }), _jsxs(FormControl, { component: "fieldset", sx: { mt: 2 }, children: [_jsx(Typography, { sx: { mb: 2 }, variant: "subtitle2", children: "Who can see" }), _jsx(Controller, { name: "teamAccessControl", control: control, rules: { required: "Privacy setting is required" }, render: function (_a) {
                                                        var field = _a.field;
                                                        return (_jsxs(RadioGroup, __assign({}, field, { sx: { gap: 1 }, children: [_jsx(FormControlLabel, { value: "public", control: _jsx(Radio, {}), sx: { mb: 1 }, label: _jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body1", fontWeight: "bold", children: "Everyone" }), _jsx(Typography, { variant: "caption", children: "Anyone on Log.Soccer can search for and view your teams." })] }) }), _jsx(FormControlLabel, { value: "private", control: _jsx(Radio, {}), label: _jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body1", fontWeight: "bold", children: "Only You" }), _jsx(Typography, { variant: "caption", children: "Your teams are hidden from everyone except you." })] }) })] })));
                                                    } }), errors.teamAccessControl && (_jsx(Typography, { color: "error", variant: "body2", children: errors.teamAccessControl.message }))] })] })] }) })] }), _jsx(Box, { sx: { display: "flex", justifyContent: "center", mt: 3 }, children: _jsx(Button, { type: "submit", variant: "contained", color: "primary", disabled: mutation.isLoading, children: mutation.isLoading ? "Saving..." : "Save" }) })] }));
};
