var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAddPlayerToTeam, useRemovePlayerFromTeam, useRetrievePlayerByUserId, useRetrieveTeam, useRetrieveUserTeamPermissions, useUpdateTeam, } from "../ApiProvider";
import { UserContext } from "../auth/UserProvider";
import { ErrorView } from "../nav/Error";
import { Forbidden } from "../nav/Forbidden";
import { Loading } from "../nav/Loading";
import { useNumericParams } from "../utils/useNumericParams";
import { TeamForm } from "./TeamForm";
export var UpdateTeam = function () {
    var id = useNumericParams().id;
    var _a = useRetrieveUserTeamPermissions({ teamId: id }), userTeamPermissions = _a.data, userTeamPermissionsIsLoading = _a.isLoading, userTeamPermissionsError = _a.error;
    var _b = (userTeamPermissions || {}).canModify, canModify = _b === void 0 ? false : _b;
    var _c = useRetrieveTeam({ id: id, enabled: canModify }), isLoading = _c.isLoading, teamError = _c.error, team = _c.data;
    var _d = useContext(UserContext), contextUser = _d.user, isContextUserLoading = _d.loading;
    var _e = useRetrievePlayerByUserId({
        userId: contextUser === null || contextUser === void 0 ? void 0 : contextUser.pk,
        enabled: canModify,
    }), player = _e.data, isPlayerLoading = _e.isLoading, playerError = _e.error;
    var addPlayerToTeamMutation = useAddPlayerToTeam({
        onMutate: function () { return console.log("Adding player to team..."); },
        onSuccess: function (data) {
            return console.log("Player added to team", data);
        },
        onError: function (error) { return console.error("Error adding player", error); },
    });
    var removePlayerFromTeamMutation = useRemovePlayerFromTeam({
        onMutate: function () { return console.log("Removing player from team..."); },
        onSuccess: function (data) {
            return console.log("Player removed from team", data);
        },
        onError: function (error) { return console.error("Error removing player", error); },
    });
    useEffect(function () {
        if (team && player) {
            var isPlayerInTeam = (team.players || []).some(function (playerId) { return playerId === player.id; });
            setAddAsPlayer(isPlayerInTeam);
            setInitialAddAsPlayer(isPlayerInTeam);
        }
    }, [team, player]);
    var _f = __read(useState(false), 2), loading = _f[0], setLoading = _f[1];
    var _g = __read(useState(undefined), 2), error = _g[0], setError = _g[1];
    var _h = __read(useState(true), 2), addAsPlayer = _h[0], setAddAsPlayer = _h[1];
    var _j = __read(useState(false), 2), initialAddAsPlayer = _j[0], setInitialAddAsPlayer = _j[1];
    var navigate = useNavigate();
    var mutation = useUpdateTeam({
        id: id,
        onMutate: function () {
            setLoading(true);
        },
        onSuccess: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var team, payload, mutation_1;
            return __generator(this, function (_a) {
                team = data.data;
                if (addAsPlayer !== initialAddAsPlayer) {
                    if (!player) {
                        throw new Error("Player must be defined!");
                    }
                    payload = { teamId: team.id, playerId: player.id };
                    mutation_1 = addAsPlayer
                        ? addPlayerToTeamMutation
                        : removePlayerFromTeamMutation;
                    try {
                        mutation_1.mutateAsync(payload);
                        console.log("Player successfully updated");
                    }
                    catch (error) {
                        console.error("Error updating player state", error);
                    }
                }
                return [2 /*return*/, navigate("/teams/".concat(team.id, "/"))];
            });
        }); },
        onError: function (error) { return setError(error); },
    });
    var onSubmit = function (data) {
        mutation.mutate(data);
    };
    if (userTeamPermissionsIsLoading) {
        return _jsx(Loading, {});
    }
    if (userTeamPermissionsError) {
        return _jsx(ErrorView, { error: userTeamPermissionsError });
    }
    if (!canModify) {
        return _jsx(Forbidden, {});
    }
    if (isLoading || loading || isContextUserLoading || isPlayerLoading) {
        return _jsx(Loading, {});
    }
    if (teamError || !team || playerError) {
        return _jsx(ErrorView, { error: teamError });
    }
    return (_jsxs(React.Fragment, { children: [error && (_jsxs(Alert, { variant: "outlined", severity: "error", children: ["Failed to update team \u2014 ", error.message, "!"] })), _jsx(Typography, { variant: "h6", children: _jsxs("strong", { children: ["Update ", team.name] }) }), _jsx(TeamForm, { team: team, onSubmit: onSubmit, addAsPlayer: addAsPlayer, setAddAsPlayer: setAddAsPlayer })] }));
};
