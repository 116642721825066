var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import { Avatar, Box, Button, IconButton, Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLogout } from "../ApiProvider";
import { UserContext } from "../auth/UserProvider";
import { MobileDrawer } from "../mobile/nav/MobileDrawer";
import { NotificationsBadge } from "../notifications/NotificationsBadge";
import { Logo } from "./Logo";
import { SearchBar } from "./SearchBar";
import { UserMenu } from "./UserMenu";
function NavBar() {
    var _a = useContext(UserContext), user = _a.user, setUser = _a.setUser, setLoading = _a.setLoading;
    var _b = __read(useState(null), 2), anchorElUser = _b[0], setAnchorElUser = _b[1];
    var _c = __read(useState(false), 2), mobileDrawerOpen = _c[0], setMobileDrawerOpen = _c[1];
    var navigate = useNavigate();
    var mutation = useLogout(function () {
        setLoading(true);
    }, function () {
        setUser(null);
        navigate("/login");
        setLoading(false);
    }, function (e) {
        console.error(e);
    });
    var handleUserMenuOpen = function (event) {
        setAnchorElUser(event.currentTarget);
    };
    var handleUserMenuClose = function () {
        setAnchorElUser(null);
    };
    return (_jsxs(Box, { sx: { flexGrow: 1 }, children: [_jsx(AppBar, { position: "static", children: _jsx(Container, { maxWidth: "lg", children: _jsxs(Toolbar, { disableGutters: true, sx: { width: "100%" }, children: [_jsxs(Box, { sx: { display: "flex", alignItems: "center", flexGrow: 1 }, children: [_jsx(IconButton, { sx: { display: { xs: "flex", md: "none" }, mr: 1 }, onClick: function () { return setMobileDrawerOpen(true); }, color: "inherit", "aria-label": "open drawer", children: _jsx(MenuIcon, {}) }), _jsx(Logo, {}), user && (_jsxs(_Fragment, { children: [_jsx(Button, { to: "/", component: Link, sx: {
                                                    ml: 2,
                                                    color: "white",
                                                    display: { xs: "none", md: "block" },
                                                }, children: "Home" }, "home-key"), _jsx(Button, { to: "/teams", component: Link, sx: {
                                                    ml: 2,
                                                    color: "white",
                                                    display: { xs: "none", md: "block" },
                                                }, children: "Teams" }, "teams-key")] }))] }), user ? (_jsxs(React.Fragment, { children: [_jsx(Box, { sx: {
                                            display: { xs: "none", md: "flex" },
                                            alignItems: "center",
                                        }, children: _jsxs(Stack, { direction: "row", spacing: 4, alignItems: "center", children: [_jsx(SearchBar, {}), _jsx(NotificationsBadge, {}), _jsx(IconButton, { onClick: handleUserMenuOpen, sx: { p: 0 }, children: _jsx(Avatar, { alt: user.firstName, src: user.picture, imgProps: { referrerPolicy: "no-referrer" } }) })] }) }), _jsx(Box, { sx: {
                                            display: { xs: "flex", md: "none" },
                                            alignItems: "center",
                                        }, children: _jsxs(Stack, { direction: "row", spacing: 1, alignItems: "center", children: [_jsx(IconButton, { sx: { p: 0.5 }, onClick: function () { return navigate("/search"); }, color: "inherit", "aria-label": "navigate to search", children: _jsx(SearchIcon, {}) }), _jsx(IconButton, { sx: { p: 0.5 }, onClick: function () { return navigate("/notifications"); }, color: "inherit", "aria-label": "navigate to notifications", children: _jsx(NotificationsIcon, {}) }), _jsx(Avatar, { alt: user.firstName, src: user.picture, sx: { width: 24, height: 24 }, imgProps: { referrerPolicy: "no-referrer" } })] }) })] })) : (_jsx(Button, { color: "inherit", component: Link, to: "/login", sx: { display: "inline-flex" }, children: "Login" }))] }) }) }), _jsx(UserMenu, { anchorElUser: anchorElUser, onUserMenuClose: handleUserMenuClose, onLogoutClick: function () {
                    handleUserMenuClose();
                    mutation.mutate();
                } }), _jsx(MobileDrawer, { mobileDrawerOpen: mobileDrawerOpen, setMobileDrawerOpen: setMobileDrawerOpen, user: user, logoutMutation: mutation })] }));
}
export default NavBar;
