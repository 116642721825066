var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
export var STALE_TIME = 1000 * 60 * 5;
export var CACHE_TIME = 1000 * 60 * 30;
var USE_RETRIEVE_USER_PLAYER_PERMISSIONS = "useRetrieveUserPlayerPermissions";
var USE_RETRIEVE_USER_TEAM_PERMISSIONS = "useRetrieveUserTeamPermissions";
var USE_RETRIEVE_USER_PRIVACY_CONTROLS = "useRetrieveUserPrivacySettings";
var USE_RETRIEVE_TEAM = "useRetrieveTeam";
var USE_RETRIEVE_TEAM_PREFERENCES = "useRetrieveTeamPreferences";
export var USE_LIST_PLAYERS_BY_TEAM_ID = "useListPlayersByTeamId";
var USE_SEARCH_PLAYERS = "useSearchPlayers";
var USE_SEARCH_TEAMS = "useSearchTeams";
var USE_LIST_INTRA_SQUAD_MATCHES_BY_TEAM_ID = "useListIntraSquadMatchesByTeamId";
var USE_LIST_INTRA_SQUAD_MATCHES_BY_PLAYER_ID = "useListIntraSquadMatchesByPlayerId";
var USE_RETRIEVE_INTRA_SQUAD_MATCH = "useRetrieveIntraSquadMatch";
var USE_LIST_PLAYER_INTRA_SQUAD_MATCH_STATS = "useListPlayerIntraSquadMatchStats";
var USE_RETRIEVE_PLAYER = "useRetrievePlayer";
var USE_RETRIEVE_PLAYER_BY_USER_ID = "useRetrievePlayerByUserId";
var USE_RETRIEVE_PLAYER_BY_EXTERNAL_USER_ID = "useRetrievePlayerByExternalUserId";
var USE_RETRIEVE_TEAMS_BY_PLAYER_ID = "useRetrieveTeamsByPlayerId";
export var useRetrieveTeam = function (_a) {
    var id = _a.id, _b = _a.enabled, enabled = _b === void 0 ? true : _b;
    return useQuery({
        queryKey: [USE_RETRIEVE_TEAM, id],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/teams/".concat(id, "/"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); },
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        enabled: !!id && enabled,
    });
};
export var useRetrievePlayersByTeamId = function (_a) {
    var teamId = _a.teamId;
    return useQuery({
        queryKey: [USE_LIST_PLAYERS_BY_TEAM_ID, teamId],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/teams/".concat(teamId, "/players/"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: !!teamId,
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onError: function (error) {
            console.error("Failed to fetch players:", error);
        },
    });
};
export var useListPlayerIntraSquadMatchStatsByIntraSquadMatch = function (_a) {
    var intraSquadMatch = _a.intraSquadMatch;
    return useQuery({
        queryKey: [USE_LIST_PLAYER_INTRA_SQUAD_MATCH_STATS, intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.id],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/intra-squad-matches/".concat(intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.team, "/player-intra-squad-match-stats/"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: !!intraSquadMatch,
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onError: function (error) {
            console.error("Failed to fetch intra-squad match stats:", error);
        },
    });
};
export var useRetrieveUserTeamPermissions = function (_a) {
    var teamId = _a.teamId;
    return useQuery({
        queryKey: [USE_RETRIEVE_USER_TEAM_PERMISSIONS, teamId],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/teams/".concat(teamId, "/user-team-permissions/"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: !!teamId,
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onError: function (error) {
            console.error("Failed to fetch user team permissions", error);
        },
    });
};
export var useRetrieveUserPlayerPermissions = function (_a) {
    var playerId = _a.playerId;
    return useQuery({
        queryKey: [USE_RETRIEVE_USER_PLAYER_PERMISSIONS, playerId],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/players/".concat(playerId, "/user-player-permissions/"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: !!playerId,
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onError: function (error) {
            console.error("Failed to fetch user player permissions", error);
        },
    });
};
export var useListIntraSquadMatchesByPlayerId = function (_a) {
    var playerId = _a.playerId;
    return useQuery({
        queryKey: [USE_LIST_INTRA_SQUAD_MATCHES_BY_PLAYER_ID, playerId],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/players/".concat(playerId, "/intra-squad-matches/"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: !!playerId,
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onError: function (error) {
            console.error("Failed to fetch players:", error);
        },
    });
};
export var useListIntraSquadMatchesByTeamId = function (_a) {
    var teamId = _a.teamId;
    return useQuery({
        queryKey: [USE_LIST_INTRA_SQUAD_MATCHES_BY_TEAM_ID, teamId],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/teams/".concat(teamId, "/intra-squad-matches/"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: !!teamId,
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onError: function (error) {
            console.error("Failed to fetch players:", error);
        },
    });
};
export var useRetrievePlayerByUserId = function (_a) {
    var userId = _a.userId, _b = _a.enabled, enabled = _b === void 0 ? true : _b;
    return useQuery({
        queryKey: [USE_RETRIEVE_PLAYER_BY_USER_ID, userId],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/users/".concat(userId, "/player/"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: !!userId && enabled,
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onError: function (error) {
            console.error("Failed to fetch player:", error);
        },
    });
};
export var useRetrievePlayerByExternalUserId = function (_a) {
    var externalUserId = _a.externalUserId;
    return useQuery({
        queryKey: [USE_RETRIEVE_PLAYER_BY_EXTERNAL_USER_ID, { externalUserId: externalUserId }],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/external-users/".concat(externalUserId, "/player/"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: !!externalUserId,
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onError: function (error) {
            console.error("Failed to fetch player:", error);
        },
    });
};
export var useUpdateUserPrivacySettings = function (_a) {
    var onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.put("/api/privacy-settings/", data)];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            queryClient.invalidateQueries([USE_RETRIEVE_USER_PRIVACY_CONTROLS]);
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useRetrieveUserPrivacySettings = function () {
    return useQuery({
        queryKey: [USE_RETRIEVE_USER_PRIVACY_CONTROLS],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/privacy-settings/")];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
    });
};
export var useRetrieveTeamsByPlayerId = function (_a) {
    var playerId = _a.playerId, _b = _a.enabled, enabled = _b === void 0 ? true : _b;
    return useQuery({
        queryKey: [USE_RETRIEVE_TEAMS_BY_PLAYER_ID, { playerId: playerId }],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/players/".concat(playerId, "/teams/"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: !!playerId && enabled,
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onError: function (error) {
            console.error("Failed to fetch player:", error);
        },
    });
};
export var useRetrievePlayer = function (_a) {
    var id = _a.id, _b = _a.enabled, enabled = _b === void 0 ? true : _b;
    return useQuery({
        queryKey: [USE_RETRIEVE_PLAYER, id],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/players/".concat(id, "/"))];
                    case 1: return [2 /*return*/, (_a.sent())
                            .data];
                }
            });
        }); },
        enabled: !!id && enabled,
        staleTime: STALE_TIME,
        cacheTime: 1000 * 60 * 2,
    });
};
export var useRetrieveIntraSquadMatch = function (_a) {
    var id = _a.id;
    return useQuery({
        queryKey: [USE_RETRIEVE_INTRA_SQUAD_MATCH, id],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/intra-squad-matches/".concat(id, "/"), {})];
                    case 1: return [2 /*return*/, (_a.sent())
                            .data];
                }
            });
        }); },
        enabled: !!id,
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
    });
};
export var useSearchTeams = function (_a) {
    var searchTerm = _a.searchTerm, _b = _a.page, page = _b === void 0 ? 1 : _b, _c = _a.enabled, enabled = _c === void 0 ? true : _c;
    return useQuery({
        queryKey: [USE_SEARCH_TEAMS, searchTerm, page],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/search/teams/", { params: { page: page, q: searchTerm } })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: enabled && searchTerm !== undefined,
    });
};
export var useSearchPlayers = function (_a) {
    var searchTerm = _a.searchTerm, _b = _a.page, page = _b === void 0 ? 1 : _b, _c = _a.enabled, enabled = _c === void 0 ? true : _c;
    return useQuery({
        queryKey: [USE_SEARCH_PLAYERS, searchTerm, page],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/search/players/", { params: { page: page, q: searchTerm } })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: enabled && searchTerm !== undefined,
    });
};
export var useRetrieveTeamPreferences = function (_a) {
    var teamId = _a.teamId, _b = _a.enabled, enabled = _b === void 0 ? true : _b;
    return useQuery({
        queryKey: [USE_RETRIEVE_TEAM_PREFERENCES, teamId],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/teams/".concat(teamId, "/preferences/"))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); },
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        enabled: !!teamId && enabled,
    });
};
export var useCreateAndAddExternalUserToTeam = function (_a) {
    var teamId = _a.teamId, onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.post("/api/teams/".concat(teamId, "/create-and-add-player/"), data)];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            queryClient.invalidateQueries([USE_RETRIEVE_TEAMS_BY_PLAYER_ID]);
            queryClient.invalidateQueries([USE_RETRIEVE_TEAM, teamId]);
            queryClient.invalidateQueries([USE_LIST_PLAYERS_BY_TEAM_ID, teamId]);
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useDeleteUser = function (_a) {
    var onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.delete("/api/auth/delete-account/")];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            queryClient.invalidateQueries();
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useCreateTeam = function (_a) {
    var onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.post("/api/teams/", data)];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            queryClient.invalidateQueries([USE_RETRIEVE_TEAMS_BY_PLAYER_ID]);
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useAddPlayerToTeam = function (_a) {
    var onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var playerId = _b.playerId, teamId = _b.teamId;
            return __generator(this, function (_c) {
                if (!teamId) {
                    throw new Error("Team ID must be defined");
                }
                return [2 /*return*/, axios.post("/api/teams/".concat(teamId, "/add-player/"), { playerId: playerId })];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            var teamId = data.data.teamId;
            queryClient.invalidateQueries([USE_RETRIEVE_TEAM, teamId]);
            queryClient.invalidateQueries([USE_RETRIEVE_TEAMS_BY_PLAYER_ID]);
            queryClient.invalidateQueries([USE_LIST_PLAYERS_BY_TEAM_ID, teamId]);
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useRemovePlayerFromTeam = function (_a) {
    var onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var playerId = _b.playerId, teamId = _b.teamId;
            return __generator(this, function (_c) {
                if (!teamId) {
                    throw new Error("Team ID must be defined");
                }
                return [2 /*return*/, axios.post("/api/teams/".concat(teamId, "/remove-player/"), { playerId: playerId })];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            var teamId = data.data.teamId;
            queryClient.invalidateQueries([USE_RETRIEVE_TEAM, teamId]);
            queryClient.invalidateQueries([USE_RETRIEVE_TEAMS_BY_PLAYER_ID]);
            queryClient.invalidateQueries([USE_LIST_PLAYERS_BY_TEAM_ID, teamId]);
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useUpdateTeam = function (_a) {
    var id = _a.id, onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!id) {
                    throw new Error("Team ID must be defined");
                }
                return [2 /*return*/, axios.put("/api/teams/".concat(id, "/"), data)];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            queryClient.invalidateQueries([USE_RETRIEVE_TEAM, id]);
            queryClient.invalidateQueries([USE_RETRIEVE_TEAMS_BY_PLAYER_ID]);
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useUpdateTeamPreference = function (_a) {
    var teamId = _a.teamId, onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!teamId) {
                            throw new Error("Team ID must be defined");
                        }
                        return [4 /*yield*/, axios.put("/api/teams/".concat(teamId, "/preferences/"), data)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            queryClient.invalidateQueries([USE_RETRIEVE_TEAM_PREFERENCES, teamId]);
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useDeletePlayer = function (_a) {
    var id = _a.id, teamIds = _a.teamIds, onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.delete("/api/players/".concat(id, "/"))];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            teamIds.forEach(function (teamId) {
                queryClient.invalidateQueries([USE_LIST_PLAYERS_BY_TEAM_ID, teamId]);
            });
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useDeleteTeam = function (_a) {
    var id = _a.id, onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.delete("/api/teams/".concat(id, "/"))];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            queryClient.invalidateQueries([USE_RETRIEVE_TEAMS_BY_PLAYER_ID]);
            queryClient.invalidateQueries([USE_RETRIEVE_TEAM, id]);
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useCreateExternalUser = function (_a) {
    var onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.post("/api/external-users/", data)];
            });
        }); },
        onMutate: onMutate,
        onSuccess: onSuccess,
        onError: onError,
    });
};
export var useLogout = function (onMutate, onSuccess, onError) {
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.post("/dj-rest-auth/logout/", {
                        token: "",
                        refresh: "",
                    })];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            onSuccess(data);
            queryClient.invalidateQueries();
        },
        onError: onError,
    });
};
export var useRetrieveLogSoccerUserDetails = function () {
    return useQuery({
        queryKey: ["useRetrieveLogSoccerUserDetails"],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, userDetails;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/dj-rest-auth/user/")];
                    case 1:
                        response = _a.sent();
                        userDetails = response.data;
                        // Ensure the response data contains a valid user
                        if (!userDetails || !userDetails.pk) {
                            throw new Error("User details are not valid.");
                        }
                        return [2 /*return*/, userDetails];
                }
            });
        }); },
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
    });
};
export var useCreateIntraSquadMatch = function (_a) {
    var team = _a.team, onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.post("/api/intra-squad-matches/", data)];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            var _a;
            if (team === undefined) {
                throw new Error("Team must be defined!");
            }
            queryClient.invalidateQueries([
                USE_LIST_INTRA_SQUAD_MATCHES_BY_TEAM_ID,
                team.id,
            ]);
            (_a = team.players) === null || _a === void 0 ? void 0 : _a.forEach(function (playerId) {
                queryClient.invalidateQueries([
                    USE_LIST_INTRA_SQUAD_MATCHES_BY_PLAYER_ID,
                    playerId,
                ]);
            });
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useUpdateIntraSquadMatch = function (_a) {
    var id = _a.id, team = _a.team, onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!id) {
                    throw new Error("Intra-Squad Match ID is not defined!");
                }
                return [2 /*return*/, axios.put("/api/intra-squad-matches/".concat(id, "/"), data)];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            var _a;
            if (team === undefined) {
                throw new Error("Team must be defined!");
            }
            queryClient.invalidateQueries([
                USE_LIST_INTRA_SQUAD_MATCHES_BY_TEAM_ID,
                team.id,
            ]);
            (_a = team.players) === null || _a === void 0 ? void 0 : _a.forEach(function (playerId) {
                queryClient.invalidateQueries([
                    USE_LIST_INTRA_SQUAD_MATCHES_BY_PLAYER_ID,
                    playerId,
                ]);
            });
            queryClient.invalidateQueries([USE_RETRIEVE_INTRA_SQUAD_MATCH, id]);
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useCreateIntraSquadMatchStats = function (onMutate, onSuccess, onError) {
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.post("/api/player-intra-squad-match-stats/", data)];
            });
        }); },
        onMutate: onMutate,
        onError: onError,
    });
};
export var useBulkUpdatePlayerIntraSquadMatchStats = function (intraSquadMatch, onMutate, onSuccess, onError) {
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.put("/api/bulk/player-intra-squad-match-stats/update/", {
                        updates: data,
                    })];
            });
        }); },
        onMutate: onMutate,
        onError: onError,
        onSuccess: function (data) {
            queryClient.invalidateQueries([
                USE_LIST_INTRA_SQUAD_MATCHES_BY_TEAM_ID,
                intraSquadMatch.team,
            ]);
            __spreadArray(__spreadArray([], __read(intraSquadMatch.teamAPlayers), false), __read(intraSquadMatch.teamBPlayers), false).forEach(function (playerId) {
                queryClient.invalidateQueries([
                    USE_LIST_INTRA_SQUAD_MATCHES_BY_PLAYER_ID,
                    playerId,
                ]);
            });
            queryClient.invalidateQueries([
                USE_RETRIEVE_INTRA_SQUAD_MATCH,
                intraSquadMatch.id,
            ]);
            onSuccess(data);
        },
    });
};
export var useCreateIntraSquadMatchResult = function (intraSquadMatch, onMutate, onSuccess, onError) {
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.post("/api/intra-squad-match-results/", data)];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            queryClient.invalidateQueries([
                USE_LIST_INTRA_SQUAD_MATCHES_BY_TEAM_ID,
                intraSquadMatch.team,
            ]);
            __spreadArray(__spreadArray([], __read(intraSquadMatch.teamAPlayers), false), __read(intraSquadMatch.teamBPlayers), false).forEach(function (playerId) {
                queryClient.invalidateQueries([
                    USE_LIST_INTRA_SQUAD_MATCHES_BY_PLAYER_ID,
                    playerId,
                ]);
            });
            queryClient.invalidateQueries([
                USE_RETRIEVE_INTRA_SQUAD_MATCH,
                intraSquadMatch.id,
            ]);
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useUpdateIntraSquadMatchResult = function (intraSquadMatch, onMutate, onSuccess, onError) {
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!(intraSquadMatch === null || intraSquadMatch === void 0 ? void 0 : intraSquadMatch.intraSquadMatchResult)) {
                    throw new Error("Intra-Squad match result not found!");
                }
                return [2 /*return*/, axios.put("/api/intra-squad-match-results/".concat(intraSquadMatch.intraSquadMatchResult.id, "/"), data)];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            queryClient.invalidateQueries([
                USE_LIST_INTRA_SQUAD_MATCHES_BY_TEAM_ID,
                intraSquadMatch.team,
            ]);
            __spreadArray(__spreadArray([], __read(intraSquadMatch.teamAPlayers), false), __read(intraSquadMatch.teamBPlayers), false).forEach(function (playerId) {
                queryClient.invalidateQueries([
                    USE_LIST_INTRA_SQUAD_MATCHES_BY_PLAYER_ID,
                    playerId,
                ]);
            });
            queryClient.invalidateQueries([
                USE_RETRIEVE_INTRA_SQUAD_MATCH,
                intraSquadMatch.id,
            ]);
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useUpdatePlayer = function (_a) {
    var id = _a.id, onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!id) {
                    throw new Error("Must define Player Id");
                }
                return [2 /*return*/, axios.put("/api/players/".concat(id, "/"), data)];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            var _a;
            queryClient.invalidateQueries([USE_RETRIEVE_PLAYER, id]);
            var userId = (_a = data.data.user) === null || _a === void 0 ? void 0 : _a.pk;
            if (userId) {
                queryClient.invalidateQueries([USE_RETRIEVE_PLAYER_BY_USER_ID, userId]);
            }
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useUpdateExternalUser = function (_a) {
    var player = _a.player, playerTeams = _a.playerTeams, onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!player) {
                    throw new Error("Must define player!");
                }
                if (!player.externalUser) {
                    throw new Error("Must define external user!");
                }
                return [2 /*return*/, axios.put("/api/external-users/".concat(player.externalUser.id, "/"), data)];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            queryClient.invalidateQueries([USE_RETRIEVE_PLAYER, player.id]);
            queryClient.invalidateQueries([
                USE_RETRIEVE_PLAYER_BY_EXTERNAL_USER_ID,
                player.externalUser.id,
            ]);
            playerTeams.forEach(function (team) {
                queryClient.invalidateQueries([USE_LIST_PLAYERS_BY_TEAM_ID, team.id]);
            });
            onSuccess(data);
        },
        onError: onError,
    });
};
