import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
export var Loading = function () {
    return (_jsx(Container, { component: "main", maxWidth: "xs", children: _jsxs(Box, { sx: {
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh", // Ensures the loading screen covers the full viewport
                textAlign: "center",
            }, children: [_jsx(CircularProgress, { size: 60, thickness: 5, sx: { mb: 4 } }), _jsx(Typography, { "data-testid": "loading", variant: "h6", sx: { mt: 2 }, children: "Loading, please wait..." })] }) }));
};
