import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
export var PlayerSearchResultAvatar = function (_a) {
    var _b;
    var playerSearchResult = _a.playerSearchResult, _c = _a.size, size = _c === void 0 ? 56 : _c;
    var displayName = "".concat(playerSearchResult.firstName, " ").concat(playerSearchResult.lastName);
    var tooltip = (_jsx(Tooltip, { title: displayName, arrow: true, children: _jsx(Avatar, { src: playerSearchResult.picture, alt: displayName, sx: { width: size, height: size }, children: (_b = displayName.match(/\b(\w)/g)) === null || _b === void 0 ? void 0 : _b.join("") }) }));
    if (!playerSearchResult) {
        return tooltip;
    }
    return (_jsx(Link, { style: { textDecoration: "none" }, to: "/players/".concat(playerSearchResult === null || playerSearchResult === void 0 ? void 0 : playerSearchResult.id, "/"), children: tooltip }, playerSearchResult === null || playerSearchResult === void 0 ? void 0 : playerSearchResult.id));
};
