var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { CACHE_TIME, STALE_TIME, USE_LIST_PLAYERS_BY_TEAM_ID, } from "../ApiProvider";
var USE_LIST_USER_TRANSFER_REQUESTS = "useListUserTransferRequests";
var USE_RETRIEVE_TRANSFER_REQUEST = "useRetrieveTransferRequest";
export var useListUserTransferRequests = function () {
    return useQuery({
        queryKey: [USE_LIST_USER_TRANSFER_REQUESTS],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/transfer-requests/user-transfer-requests/")];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onError: function (error) {
            console.error("Failed to fetch transfer requests:", error);
        },
    });
};
export var useRetrieveTransferRequest = function (_a) {
    var id = _a.id;
    return useQuery({
        queryKey: [USE_RETRIEVE_TRANSFER_REQUEST, id],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/api/transfer-requests/".concat(id, "/"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); },
        enabled: !!id,
        staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onError: function (error) {
            console.error("Failed to fetch transfer request:", error);
        },
    });
};
export var useCreateTransferRequest = function (_a) {
    var team = _a.team, onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!team) {
                    throw new Error("Team is not defined!");
                }
                return [2 /*return*/, axios.post("/api/teams/".concat(team.id, "/transfer-requests/"))];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            queryClient.invalidateQueries([USE_LIST_USER_TRANSFER_REQUESTS]);
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useProcessTransferRequest = function (_a) {
    var transferRequest = _a.transferRequest, onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (processTransferRequest) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!transferRequest) {
                    throw new Error("Transfer Request is not defined!");
                }
                return [2 /*return*/, axios.put("/api/process-transfer-request/".concat(transferRequest.id, "/"), processTransferRequest)];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            queryClient.invalidateQueries([
                USE_LIST_PLAYERS_BY_TEAM_ID,
                transferRequest.team,
            ]);
            onSuccess(data);
        },
        onError: onError,
    });
};
export var useCancelTransferRequest = function (_a) {
    var transferRequest = _a.transferRequest, onMutate = _a.onMutate, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!transferRequest) {
                    throw new Error("Transfer Request is not defined!");
                }
                return [2 /*return*/, axios.delete("/api/cancel-transfer-request/".concat(transferRequest.id, "/"))];
            });
        }); },
        onMutate: onMutate,
        onSuccess: function (data) {
            queryClient.invalidateQueries([USE_LIST_USER_TRANSFER_REQUESTS]);
            queryClient.invalidateQueries([
                USE_RETRIEVE_TRANSFER_REQUEST,
                transferRequest.id,
            ]);
            onSuccess(data);
        },
        onError: onError,
    });
};
