var _a, _b;
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { getPlayerResult, Result } from "./utils";
var resultToLetterMap = (_a = {},
    _a[Result.Win] = "W",
    _a[Result.Draw] = "D",
    _a[Result.Loss] = "L",
    _a);
var resultColorMap = (_b = {},
    _b[Result.Win] = "#148a00",
    _b[Result.Draw] = "#555658",
    _b[Result.Loss] = "#e51755",
    _b);
export var FormGuide = function (_a) {
    var matches = _a.matches, playerId = _a.playerId, _b = _a.formSize, formSize = _b === void 0 ? 5 : _b;
    var form = matches.slice(0, formSize).map(function (match) {
        var result = getPlayerResult(playerId, match);
        var score = match.intraSquadMatchResult
            ? "".concat(match.intraSquadMatchResult.teamAScore, "-").concat(match.intraSquadMatchResult.teamBScore)
            : "N/A";
        var matchLink = "/intra-squad-matches/".concat(match.id);
        return { result: result, score: score, matchLink: matchLink };
    });
    return (_jsx(Box, { sx: { display: "flex", justifyContent: "center", gap: 1, marginTop: 2 }, children: form.length > 0 ? (form.map(function (_a, index) {
            var result = _a.result, score = _a.score, matchLink = _a.matchLink;
            return (_jsx(Tooltip, { title: score, arrow: true, children: _jsx(Link, { to: matchLink, style: { textDecoration: "none" }, children: _jsx(Box, { sx: {
                            width: 25,
                            height: 25,
                            bgcolor: result !== undefined
                                ? resultColorMap[result]
                                : "transparent",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            fontWeight: "bold",
                        }, children: result !== undefined ? resultToLetterMap[result] : "-" }, index) }) }, index));
        })) : (_jsx(Box, { sx: {
                width: 25,
                height: 25,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
            }, children: "-" })) }));
};
