import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRetrievePlayer, useRetrieveUserPlayerPermissions, useUpdatePlayer, } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Forbidden } from "../nav/Forbidden";
import { Loading } from "../nav/Loading";
import ErrorSnackbar from "../utils/ErrorSnackbar";
import { useNumericParams } from "../utils/useNumericParams";
import { PlayerDisplayNameLink } from "./PlayerDisplayNameLink";
import { PlayerForm } from "./PlayerForm";
export var UpdatePlayerForm = function () {
    var id = useNumericParams().id;
    var navigate = useNavigate();
    var _a = useRetrieveUserPlayerPermissions({ playerId: id }), userPlayerPermissions = _a.data, userPlayerPermissionsIsLoading = _a.isLoading, userPlayerPermissionsError = _a.error;
    var _b = (userPlayerPermissions || {}).canModify, canModify = _b === void 0 ? false : _b;
    var _c = useRetrievePlayer({ id: id, enabled: canModify }), useRetrievePlayerIsLoading = _c.isLoading, useRetrievePlayerError = _c.error, player = _c.data;
    var updatePlayerMutation = useUpdatePlayer({
        id: id,
        onMutate: function () { },
        onSuccess: function (data) {
            navigate("/players/".concat(id, "/"));
            return data.data;
        },
        onError: console.error,
    });
    if (userPlayerPermissionsError) {
        return _jsx(ErrorView, { error: userPlayerPermissionsError });
    }
    if (userPlayerPermissionsIsLoading) {
        return _jsx(Loading, {});
    }
    if (!canModify) {
        return _jsx(Forbidden, {});
    }
    var loading = useRetrievePlayerIsLoading || updatePlayerMutation.isLoading;
    if (loading) {
        return _jsx(Loading, {});
    }
    if (useRetrievePlayerError) {
        return _jsx(ErrorView, { error: useRetrievePlayerError });
    }
    if (!player) {
        return _jsx(ErrorView, { error: new Error("Could not load player!") });
    }
    return (_jsxs(React.Fragment, { children: [_jsx(ErrorSnackbar, { open: !!updatePlayerMutation.isError, message: "Failed to update player!" }), _jsx(Typography, { variant: "h6", children: _jsxs("strong", { children: ["Update ", _jsx(PlayerDisplayNameLink, { player: player })] }) }), _jsx(PlayerForm, { player: player, onSubmit: updatePlayerMutation.mutate, onCancel: function () {
                    navigate(-1);
                } })] }));
};
