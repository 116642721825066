import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Drawer, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
export var MobileDrawer = function (_a) {
    var mobileDrawerOpen = _a.mobileDrawerOpen, setMobileDrawerOpen = _a.setMobileDrawerOpen, user = _a.user, logoutMutation = _a.logoutMutation;
    return (_jsx(Drawer, { anchor: "left", open: mobileDrawerOpen, onClose: function () { return setMobileDrawerOpen(false); }, children: _jsxs(Box, { sx: { width: 280, p: 2 }, role: "presentation", children: [user && (_jsxs(_Fragment, { children: [_jsx(MenuItem, { component: Link, to: "/", onClick: function () { return setMobileDrawerOpen(false); }, children: "Home" }), _jsx(MenuItem, { component: Link, to: "/teams", onClick: function () { return setMobileDrawerOpen(false); }, children: "Teams" }), _jsx(Divider, { sx: { my: 2 } }), _jsx(MenuItem, { component: Link, to: "/account", onClick: function () { return setMobileDrawerOpen(false); }, children: "My Account" }), _jsx(MenuItem, { component: Link, to: "/privacy-settings", onClick: function () { return setMobileDrawerOpen(false); }, children: "Privacy Settings" }), _jsx(MenuItem, { onClick: function () {
                                setMobileDrawerOpen(false);
                                logoutMutation.mutate();
                            }, children: "Logout" })] })), !user && (_jsx(MenuItem, { component: Link, to: "/login", onClick: function () { return setMobileDrawerOpen(false); }, children: "Login" }))] }) }));
};
