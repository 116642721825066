import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Container, List, Pagination, Paper, Typography, } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import { useListNotifications, useMarkNotificationAsRead, } from "../api/NotificationApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import ErrorSnackbar from "../utils/ErrorSnackbar";
import { buildNotificationUrl } from "./NotificationsBadge";
export var Notifications = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var queryParams = new URLSearchParams(location.search);
    var page = Number.parseInt(queryParams.get("page") || "1");
    var _a = useListNotifications({ page: page }), notifications = _a.data, isLoading = _a.isLoading, error = _a.error;
    var markNotificationAsReadMutation = useMarkNotificationAsRead();
    var handlePageChange = function (_event, value) {
        queryParams.set("page", value.toString());
        navigate("/notifications?".concat(queryParams.toString()));
    };
    var handleClick = function (notification) {
        if (notification.unread) {
            markNotificationAsReadMutation.mutate(notification.id);
        }
        navigate(buildNotificationUrl(notification));
    };
    if (isLoading)
        return _jsx(Loading, {});
    if (!notifications)
        return _jsx(ErrorView, {});
    return (_jsxs(Container, { maxWidth: "sm", sx: { mt: 4 }, children: [_jsx(ErrorSnackbar, { open: !!error, message: "Failed to load notifications!" }), _jsx(Typography, { variant: "h4", gutterBottom: true, children: "Notifications" }), notifications.count === 0 ? (_jsx(Typography, { children: "No notifications found." })) : (_jsx(List, { disablePadding: true, children: notifications.results.map(function (notification) { return (_jsxs(Paper, { onClick: function () { return handleClick(notification); }, elevation: notification.unread ? 3 : 1, sx: {
                        mb: 2,
                        p: 2,
                        bgcolor: notification.unread ? "#f4f7fe" : "white",
                        borderLeft: notification.unread
                            ? "4px solid #1976d2"
                            : "4px solid transparent",
                        cursor: "pointer",
                        transition: "background 0.2s",
                        "&:hover": {
                            backgroundColor: "#f0f0f0",
                        },
                    }, children: [_jsx(Typography, { variant: "body1", children: notification.description }), _jsx(Typography, { variant: "caption", color: "text.secondary", sx: { mt: 0.5, display: "block" }, children: formatDistanceToNow(new Date(notification.timestamp), {
                                addSuffix: true,
                            }) })] }, notification.id)); }) })), _jsx(Box, { display: "flex", justifyContent: "center", mt: 2, children: _jsx(Pagination, { showFirstButton: true, showLastButton: true, count: notifications.pageCount, page: page, onChange: handlePageChange }) })] }));
};
