import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@mui/material";
import React from "react";
import { useCancelTransferRequest, useCreateTransferRequest, useListUserTransferRequests, } from "../api/TransferRequestApiProvider";
import ErrorSnackbar from "../utils/ErrorSnackbar";
export var TransferRequestButton = function (_a) {
    var team = _a.team;
    var _b = useListUserTransferRequests(), userTransferRequests = _b.data, userTransferRequestsError = _b.error, userTransferRequestsIsLoading = _b.isLoading, isRefetching = _b.isRefetching;
    var teamTransferRequest = userTransferRequests === null || userTransferRequests === void 0 ? void 0 : userTransferRequests.find(function (transferRequest) {
        return transferRequest.team === team.id;
    });
    var submitTransferRequestMutation = useCreateTransferRequest({
        team: team,
        onMutate: function () { },
        onSuccess: console.log,
        onError: console.error,
    });
    var deleteTransferRequestMutation = useCancelTransferRequest({
        transferRequest: teamTransferRequest,
        onMutate: function () { },
        onSuccess: console.log,
        onError: console.error,
    });
    var handleSubmit = function () {
        submitTransferRequestMutation.mutate();
    };
    var handleCancel = function () {
        if (teamTransferRequest) {
            deleteTransferRequestMutation.mutate();
        }
    };
    return (_jsxs(React.Fragment, { children: [_jsx(ErrorSnackbar, { open: submitTransferRequestMutation.isError, message: "Failed to submit transfer request! Please try again." }), _jsx(ErrorSnackbar, { open: deleteTransferRequestMutation.isError, message: "Failed to cancel transfer request! Please try again." }), _jsx(ErrorSnackbar, { open: !!userTransferRequestsError, message: "Failed to load transfer requests!" }), isRefetching || userTransferRequestsIsLoading ? (_jsx(Button, { disabled: true, variant: "contained", color: "primary", children: "Fetching Transfer Status..." })) : deleteTransferRequestMutation.isLoading ? (_jsx(Button, { disabled: true, variant: "contained", color: "primary", children: "Cancelling..." })) : submitTransferRequestMutation.isLoading ? (_jsx(Button, { disabled: true, variant: "contained", color: "primary", children: "Submitting..." })) : teamTransferRequest ? (_jsx(Button, { onClick: handleCancel, variant: "contained", color: "primary", children: "Cancel Transfer Request" })) : (_jsx(Button, { onClick: handleSubmit, variant: "contained", color: "primary", children: "Submit Transfer Request" }))] }));
};
