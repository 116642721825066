import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
export var Forbidden = function () {
    var navigate = useNavigate();
    return (_jsx(Container, { component: "main", maxWidth: "xs", children: _jsxs(Box, { sx: {
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
            }, children: [_jsx(LockIcon, { sx: { fontSize: 80, color: "error.main", mb: 3 } }), _jsx(Typography, { variant: "h3", gutterBottom: true, children: "403 - Red Card!" }), _jsx(Typography, { variant: "h6", sx: { mb: 4 }, children: "You don\u2019t have permission to view this page." }), _jsx(Button, { variant: "contained", color: "primary", onClick: function () {
                        navigate("/");
                    }, sx: { mt: 2 }, children: "Go Back to Home" })] }) }));
};
