var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useProcessTransferRequest } from "../api/TransferRequestApiProvider";
import { useRetrievePlayersByTeamId } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
export var ManagerTransferRequestView = function (_a) {
    var transferRequest = _a.transferRequest, team = _a.team;
    var _b = transferRequest || {}, playerFirstName = _b.playerFirstName, playerLastName = _b.playerLastName, teamId = _b.team, player = _b.player, status = _b.status;
    var _c = useRetrievePlayersByTeamId({ teamId: teamId }), players = _c.data, playersError = _c.error, playersIsLoading = _c.isLoading;
    var _d = useForm({
        defaultValues: { mergePlayerId: undefined },
    }), control = _d.control, handleSubmit = _d.handleSubmit, setValue = _d.setValue;
    var handleClear = function (event) {
        event.stopPropagation();
        setValue("mergePlayerId", undefined);
    };
    var navigate = useNavigate();
    var processTransferRequestMutation = useProcessTransferRequest({
        transferRequest: transferRequest,
        onMutate: function () { },
        onSuccess: function () {
            navigate("/teams/".concat(teamId, "/"));
        },
        onError: console.error,
    });
    if (playersIsLoading) {
        return _jsx(Loading, {});
    }
    if (!transferRequest || !players || playersError) {
        return _jsx(ErrorView, { error: playersError });
    }
    var mergeablePlayers = players.filter(function (player) { return player.user === null; });
    if (status !== "PENDING") {
        return (_jsx(Box, { sx: { display: "flex", justifyContent: "center", minHeight: "100vh" }, children: _jsxs(Box, { sx: { p: 3, maxWidth: 400, width: "100%" }, children: [_jsx(Typography, { variant: "h5", gutterBottom: true, children: "Transfer Request" }), _jsxs(Typography, { children: ["The transfer of", " ", _jsxs(Link, { style: { textDecoration: "none" }, to: "/players/".concat(player, "/"), children: [playerFirstName, " ", playerLastName] }), " ", "to", " ", _jsx(Link, { style: { textDecoration: "none" }, to: "/teams/".concat(teamId, "/"), children: team.name }), " ", "was ", _jsx("strong", { children: status }), "."] })] }) }));
    }
    return (_jsx(Box, { sx: { display: "flex", justifyContent: "center", minHeight: "100vh" }, children: _jsxs(Box, { sx: { p: 3, maxWidth: 400, width: "100%" }, children: [_jsx(Typography, { variant: "h5", gutterBottom: true, children: "Transfer Request" }), _jsxs(Typography, { children: [_jsxs(Link, { style: { textDecoration: "none" }, to: "/players/".concat(player, "/"), children: [playerFirstName, " ", playerLastName] }), " ", "has requested a transfer to", " ", _jsx(Link, { style: { textDecoration: "none" }, to: "/teams/".concat(teamId, "/"), children: team.name }), "."] }), _jsxs(Typography, { variant: "caption", children: ["You can accept the request as-is or merge ", playerFirstName, " with an existing player on the team."] }), mergeablePlayers.length > 0 && (_jsxs(FormControl, { fullWidth: true, sx: { mt: 2 }, "data-testid": "merge-player-dropdown", children: [_jsx(InputLabel, { children: "Merge With Existing Player (Optional)" }), _jsx(Controller, { name: "mergePlayerId", control: control, render: function (_a) {
                                var field = _a.field;
                                return (_jsx(Select, __assign({}, field, { label: "Merge With Existing Player (Optional)", endAdornment: _jsx("div", { onClick: handleClear, style: { marginRight: 20, cursor: "pointer" }, children: _jsx(ClearIcon, { "data-testid": "clear-merge-player" }) }), children: mergeablePlayers.map(function (player) { return (_jsxs(MenuItem, { "data-testid": "merge-player-id-".concat(player.id), value: player.id, children: [player.externalUser.firstName, " ", player.externalUser.lastName] }, player.id)); }) })));
                            } })] })), _jsxs(Box, { sx: { display: "flex", justifyContent: "center", gap: 2, mt: 3 }, children: [_jsx(Button, { variant: "contained", color: "primary", onClick: handleSubmit(function (processTransferRequest) {
                                processTransferRequest.status = "ACCEPTED";
                                processTransferRequestMutation.mutate(processTransferRequest);
                            }), children: "Accept" }), _jsx(Button, { variant: "outlined", color: "error", onClick: handleSubmit(function (processTransferRequest) {
                                processTransferRequest.status = "REJECTED";
                                processTransferRequestMutation.mutate(processTransferRequest);
                            }), children: "Reject" })] })] }) }));
};
